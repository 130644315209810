
import { defineComponent } from "vue";
import ApiService from "@/core/services/ApiService";
import EntityTable from "@/components/EntityTable.vue";
import Workdayfilters from "@/components/workday/WorkdayFilters.vue";
import TableExplorer from "@/components/TableExplorer.vue";

export default defineComponent({
  name: "kt-widget-12",
  components: {
    TableExplorer,
    Workdayfilters,
  },
  props: {
    widgetClasses: String,
  },
  methods: {
    getStatusText(status) {
      switch (status) {
        case "open":
          return "Abierto";
        case "closed":
          return "En Tratamiento";
        case "processed":
          return "Cerrado";
      }

      return "Desconocido";
    },
    getStatusClass(status) {
      switch (status) {
        case "open":
          return "-danger";
        case "closed":
          return "-warning";
        case "processed":
          return "-success";
      }

      return "Desconocido";
    },
  },
  data() {
    return {
      filters: {},
      columns: [
        {
          name: "date",
          label: "Fecha",
        },
        {
          name: "status",
          label: "Estado",
        },
        {
          name: "supervisor_name",
          label: "Supervisora",
        },
        {
          name: "visitor_name",
          label: "Visitadora",
        },
        {
          name: "donor_count",
          label: "Donantes",
        },
      ],
    };
  },
});
